/**
 * This is a seperate file to help with code splitting
 */
import React from "react";

import { ClientCounterEvent } from "../../util/clientCounterEvent.js";
import {
  ChartCellIcon,
  CodeCellIcon,
  ComponentIcon,
  DropdownInputIcon,
  FilterCellIcon,
  SQLCellIcon,
  TextCellIcon,
  UnorderedListIcon,
  WritebackCellIcon,
} from "../icons/CustomIcons.js";

type CellFilterShowTypeKey =
  | "All"
  | "Code"
  | "Components"
  | "Input"
  | "Sql"
  | "Text"
  | "Transformations"
  | "Visualizations"
  | "Writeback";

export const CellFilterShowType: Record<
  CellFilterShowTypeKey,
  {
    display: string;
    label: string;
    typename?: string | readonly string[];
    icon?: JSX.Element;
    counterEvent: ClientCounterEvent;
  }
> = {
  All: {
    display: "All",
    label: "all",
    icon: <UnorderedListIcon />,
    counterEvent: "sidebar.outline.filter.all",
  },
  Code: {
    display: "Code",
    label: "Code",
    typename: "CodeCell",
    icon: <CodeCellIcon />,
    counterEvent: "sidebar.outline.filter.code",
  },
  Components: {
    display: "Components",
    label: "Components",
    typename: "ComponentImportCell",
    icon: <ComponentIcon />,
    counterEvent: "sidebar.outline.filter.components",
  },
  Input: {
    display: "Input parameters",
    label: "Input parameters",
    typename: "Parameter",
    icon: <DropdownInputIcon />,
    counterEvent: "sidebar.outline.filter.inputs",
  },
  Sql: {
    display: "SQL",
    label: "SQL",
    typename: "SqlCell",
    icon: <SQLCellIcon />,
    counterEvent: "sidebar.outline.filter.sql",
  },
  Text: {
    display: "Text + Markdown",
    label: "Text + Markdown",
    typename: ["MarkdownCell", "TextCell"],
    icon: <TextCellIcon />,
    counterEvent: "sidebar.outline.filter.text",
  },
  Transformations: {
    display: "Transformations",
    label: "Transformations",
    typename: ["FilterCell", "PivotCell"],
    icon: <FilterCellIcon />,
    counterEvent: "sidebar.outline.filter.transformations",
  },
  Visualizations: {
    display: "Visualizations",
    label: "Visualizations",
    typename: ["ChartCell", "DisplayTableCell", "MetricCell", "MapCell"],
    icon: <ChartCellIcon />,
    counterEvent: "sidebar.outline.filter.visualizations",
  },
  Writeback: {
    display: "Writeback",
    label: "Writeback",
    typename: ["WritebackCell"],
    icon: <WritebackCellIcon />,
    counterEvent: "sidebar.outline.filter.writeback",
  },
};

type OutlineTypeKey = "Full" | "Minimal";

export const OutlineType: Record<
  OutlineTypeKey,
  {
    display: string;
    label: string;
  }
> = {
  Full: {
    display: "Full",
    label: "Full",
  },
  Minimal: {
    display: "Minimal",
    label: "Minimal",
  },
};
